import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "dxa_modal" },
    [
      _c(VCardTitle, [
        _c("h3", { staticClass: "dxa_modal_title h3" }, [
          _vm._v(" " + _vm._s(_vm.$t("manage_equity_holding")) + " "),
        ]),
      ]),
      _c(
        VCardText,
        [
          _c(
            VContainer,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.add_equity_holding()
                    },
                  },
                },
                [
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("name"),
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.equityHolding.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.equityHolding, "Name", $$v)
                      },
                      expression: "equityHolding.Name",
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("cpf"),
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.equityHolding.RegistryNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.equityHolding, "RegistryNumber", $$v)
                      },
                      expression: "equityHolding.RegistryNumber",
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("nationality"),
                      rules: [_vm.required],
                    },
                    model: {
                      value: _vm.equityHolding.Nationality,
                      callback: function ($$v) {
                        _vm.$set(_vm.equityHolding, "Nationality", $$v)
                      },
                      expression: "equityHolding.Nationality",
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("capital"),
                      rules: [_vm.required],
                      type: "number",
                      suffix: "%",
                    },
                    on: {
                      change: function ($event) {
                        _vm.equityHolding.Capital = parseFloat(
                          _vm.equityHolding.Capital
                        )
                      },
                    },
                    model: {
                      value: _vm.equityHolding.Capital,
                      callback: function ($$v) {
                        _vm.$set(_vm.equityHolding, "Capital", $$v)
                      },
                      expression: "equityHolding.Capital",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "my-2" },
                    [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("is_politically_exposed")) + " "
                        ),
                      ]),
                      _c(
                        VRow,
                        { staticClass: "mx-12" },
                        [
                          _c(
                            VRadioGroup,
                            {
                              attrs: { center: "" },
                              model: {
                                value: _vm.equityHolding.PoliticallyExposed,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.equityHolding,
                                    "PoliticallyExposed",
                                    $$v
                                  )
                                },
                                expression: "equityHolding.PoliticallyExposed",
                              },
                            },
                            [
                              _c(VRadio, {
                                attrs: { value: true },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("yes")))]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                              _c(VRadio, {
                                attrs: { value: false },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("no")))]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticClass: "pl-0" },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            type: "submit",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.add_equity_holding()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("save")))]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: { color: "red", "min-width": "100" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }